import React, { useEffect, useState } from "react";
import {
  StyledCardsHive,
  StyledCardsHiveImage,
  StyledCardsHiveInfo,
  StyledCardsHiveName,
  StyledCardsHiveDescription,
  StyledCardsHiveRole,
} from "./style";

export default ({ hive }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [currentHive, setCurrentHive] = useState(null);

  useEffect(() => {
    setCurrentHive(null);
  }, [currentTab]);

  return (
      
    <StyledCardsHive
      key={hive.index}
      showInfo={currentHive === hive.index}
      onMouseOver={() => setCurrentHive(hive.index)}
      onMouseLeave={() => setCurrentHive(null)}
    >
      <StyledCardsHiveImage bg={hive.image} />
      <StyledCardsHiveInfo>
        <StyledCardsHiveName
          dangerouslySetInnerHTML={{ __html: hive.name }}
        />
        <StyledCardsHiveRole
          dangerouslySetInnerHTML={{ __html: hive.role }}
        />
        <StyledCardsHiveDescription
          dangerouslySetInnerHTML={{
            __html: hive.description,
          }}
        />
      </StyledCardsHiveInfo>
    </StyledCardsHive>
  );
};
